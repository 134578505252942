
import React, {useState, useEffect} from 'react'
import {Grid, Col, Row, Title, BRL, OnlySmall, OnlyLarge, Wrapper, ContainerPadding, ResponsiveImage, Section, TextInline, Container} from '../theme'
import {margins} from "../theme/config.json";
import {logoLarge, logoSmall} from '../theme/icons';
import {useSelector} from 'react-redux'
import Button from './Button'
import Report from './Report'
import Warning from './Warning'
import { Link } from 'react-router-dom';

const PrintPage = ()=>{
    const student = useSelector(state=>state.data.student);
    const courseData = useSelector(state=>state.data.courseData);
    const client = useSelector(state=>state.data.client);
    const [planning, setPlanning] = useState(null);

    useEffect(() => {
        if (courseData && !planning){
            
            const courseplanning = courseData.getAvailableForPlanning().planning;
            
            setPlanning(Object.keys(courseData.getAvailableForPlanning().planning).map((year, idx, years)=>{

                const getTrimester = (trim)=>{
                    return courseplanning[year].filter((course)=>(course.planned.trimester === trim));
                }

                const trimesters = [getTrimester(0), getTrimester(1), getTrimester(2)]
                
                return (
                    <Section key={idx}>
                        <Title style={{textAlign: "left"}}>{`${parseInt(year)}/${parseInt(year)+1}`}</Title>
                        {trimesters.map((trimester, index)=>{

                            return (
                                <Section>
                                    <TextInline>
                                        <b>
                                            {`Trimester ${index + 1}`}
                                        </b> < br/>
                                        {trimester.map((course, idx, courses)=>{
                                            return (
                                                <span>{course.name} <br/></span>
                                            )
                                        })}
                                    </TextInline>
                                </Section>
                            )
                        })}
                        <br /><br />
                    </Section>
            )})
            )

            setTimeout(() => {
                window.print();
            }, 200);        
        }
    }, [courseData]);

    const getMaxTerm =()=>{
        if (student.term === 0){
            return `(${student.year+7} - Term 3)`
        } else {
            return `(${student.year+8} - Term ${student.term+1})`
        }
    }
    
    

    if (!client){
        return null;
    } else if (!student && client.environment.dev){
        client.login('pieter@upou.edu.ph', 'qwerty');
    }

    if (!student || !courseData){
        return <Grid style={{paddingTop: margins.grid.top}}>
        <Row center="xs">
            <Col xs={12} md={6}>
            <Title> <br/><br/><br/> Nothing found, are you sure that you're logged in? 
            <br/><br/>
            <Button  text="go home" link="/"/>
        </Title>
            </Col>
        </Row>
    </Grid>
    }

    return (
        <Grid style={{paddingTop: margins.grid.top}}>
            <Row center="xs">
                <Col xs={12} md={6}>
                    <Link to={"/"}>
                    <ResponsiveImage src={logoLarge}  />
                    <Title>{`${student.firstname} ${student.lastname}`}</Title>
                    </Link>
                </Col>
            </Row>
            <Warning  print/> <br />
            <Wrapper>
                <ContainerPadding>
                    <b>
                        {student.email} <br/> 
                        {student.studentnumber} <br/> 
                        {/* Enrollment: {student.year} - { ["Term 1", "Term 2", "Term 3"][student.term]} */}
                        {/* Disable term now, maybe later we find a better fix {getMaxTerm()} */}
                        Enrollment: {student.year} - {"Term " + (student.term+1)} 
                    </b>
                </ContainerPadding>
            </Wrapper>
            {planning}
            <br/><br/><br/><br/><br/>
            <Report />
        </Grid>
    )
}

export default PrintPage;